<template>
    <b-modal
      :id="modalName"
      :ref="modalName"
      :title="title"
      @show="open"
      @hidden="close"
      @ok="ok"
      :hide-header="onlyBody"
      :hide-footer="onlyBody"
      :ok-title="okTitle"
      :cancel-title="cancelTitle"
      :ok-variant="okVariant"
      :cancel-variant="cancelVariant"
    >
        <slot></slot>
    </b-modal>
</template>

<style lang="stylus">

@import '../../style/colors.styl';
@import '../../style/fonts.styl';

.modal-content
    background-color secondary
    border-radius 8px
    color black
    // font-family 'Rubik-Medium'
    font-size 18px
    font-weight 600
    line-height 20px

.modal-body
    border-radius 8px
    margin 5px

.modal
    text-align: center

@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal-dialog
    min-width 900px
    display: inline-block
    text-align: left
    vertical-align: middle

.close
    color primary

</style>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'Title'
    },
    prevent: {
      type: Boolean,
      default: false
    },
    onlyBody: {
      type: Boolean,
      default: false
    },
    okTitle: {
      type: String,
      default: 'Ok'
    },
    cancelTitle: {
      type: String,
      default: 'Cancelar'
    },
    okVariant: {
      type: String,
      default: 'primary'
    },
    cancelVariant: {
      type: String,
      default: 'danger'
    }
  },
  computed: {
    modalName () {
      return this.name + '-modal'
    }
  },
  mounted () {
    // Removido para evitar que o modal abra automaticamente
  },
  methods: {
    ok (e) {
      if (this.prevent) e.preventDefault()
      // ...
      this.$emit('ok')
    },
    open () {
      // ...
      this.$emit('open')
    },
    close () {
      // ...
      if (!this.prevent) {
        this.$nextTick(() => {
          this.$bvModal.hide(this.modalName)
        })
      }
      this.$emit('close')
    }
  }
}
</script>
