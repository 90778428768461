import { baseMutations, baseActions } from '../functions'

export default {
  namespaced: true,
  state: {
    // Info
    items: [],
    title: 'Estatísticas',
    name: 'Estatísticas',
    loading: true,

    // Menu
    menu: {
      title: 'Estatísticas',
      icon: { key: 'chart', custom: true }
    },

    // Routes - Components actions
    actions: {
      // Main Action
      component: 'Cards.estatisticas',
      props: { title: 'Estatísticas do Sistema', module: 'estatisticas', modelKey: 'stats' },
      children: [
        {
          key: 'add',
          scope: 'Title',
          event: 'link',
          options: { label: 'Nova Estatística', icon: 'add' },
          component: 'Form',
          props: { title: 'Cadastrar nova estatística', module: 'estatisticas', formKey: 'save', requests: 'add' }
        },
        {
          key: 'edit',
          scope: 'Buttons',
          options: { theme: 'warning' },
          event: 'link',
          params: ['id'],
          component: 'Form',
          props: route => ({ itemId: route.params.id, title: 'Editar estatística', module: 'estatisticas', formKey: 'save', requests: 'edit' })
        },
        {
          key: 'delete',
          scope: 'Buttons',
          options: { theme: 'danger' },
          event: 'modal',
          component: 'Delete',
          props: item => ({ item: item, module: 'estatisticas' })
        }
      ]
    },

    // API Get index
    get: {
      urn: 'estatisticas/index',
      response: 'estatisticas'
    },

    // API routes
    requests: {
      add: {
        title: 'Cadastrar nova Estatística',
        success: 'Estatística cadastrada com sucesso',
        urn: 'estatisticas/create',
        response: 'estatistica',
        method: 'post',
        dispatch: 'add'
      },
      edit: {
        title: 'Editar Estatística',
        success: 'Estatística editada com sucesso',
        urn: 'estatisticas/edit',
        response: 'estatistica',
        method: 'post',
        dispatch: 'edit'
      },
      delete: {
        title: 'Deletar Estatística',
        success: 'Estatística deletada com sucesso',
        urn: 'estatisticas/delete?estatistica_id=:id',
        method: 'delete',
        dispatch: 'delete'
      }
    },

    // Models
    models: {
      stats: [
        { key: 'totalEmpresas', label: 'Total de Empresas', slot: 'Total de Empresas' },
        { key: 'empresasAtivas', label: 'Empresas Ativas', slot: 'Empresas Ativas' },
        { key: 'totalFuncionarios', label: 'Total de Funcionários', slot: 'Total de Funcionários' },
        { key: 'funcionariosAtivos', label: 'Funcionários Ativos', slot: 'Funcionários Ativos' },
        { key: 'totalEquipamentos', label: 'Total de Equipamentos', slot: 'Total de Equipamentos' },
        { key: 'equipamentosEmUso', label: 'Equipamentos em Uso', slot: 'Equipamentos em Uso' }
      ]
    },

    // Forms
    forms: {
      save: [
        { key: 'estatistica_id', type: 'hidden', from: 'item', value: 'id' },
        { key: 'totalEmpresas', label: 'Total de Empresas', type: 'number', col: 6 },
        { key: 'empresasAtivas', label: 'Empresas Ativas', type: 'number', col: 6 },
        { key: 'totalFuncionarios', label: 'Total de Funcionários', type: 'number', col: 6 },
        { key: 'funcionariosAtivos', label: 'Funcionários Ativos', type: 'number', col: 6 },
        { key: 'totalEquipamentos', label: 'Total de Equipamentos', type: 'number', col: 6 },
        { key: 'equipamentosEmUso', label: 'Equipamentos em Uso', type: 'number', col: 6 }
      ]
    }
  },
  mutations: Object.assign({}, baseMutations, {
    // ...
  }),
  actions: Object.assign({}, baseActions, {
    // ...
  })
}
