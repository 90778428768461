<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h1>Análises</h1>
        </div>
        <div class="box">
            <b-form-group label="" class="mb-0">
                <div class="lineflex">
                    <b-button variant="" @click="toggleFilters">
                        Filtrar
                    </b-button>
                    <b-form-select v-model="sortBy" :options="sortOptions" @change="applySort" class="listbox">
                        <b-form-select-option :value="null">Ordenar</b-form-select-option>
                    </b-form-select>
                </div>
                <div class="filters" v-if="showFilters">
                    <!-- Campos de filtro em linha -->
                    <div class="filters-row">
                        <b-form-select v-if="isSuperAdmin" v-model="filters.empresa" :options="empresas"
                            placeholder="Filtrar por Empresa" @change="fetchFuncionarios">
                            <b-form-select-option :value="null">Todas as Empresas</b-form-select-option>
                        </b-form-select>
                        <b-form-select v-if="isAdmin" v-model="filters.funcionario" :options="funcionarios"
                            placeholder="Filtrar por Funcionário">
                            <b-form-select-option :value="null">Todos os Funcionários</b-form-select-option>
                        </b-form-select>
                        <b-form-select v-model="filters.localidade" :options="localidades"
                            placeholder="Filtrar por Localidade">
                            <b-form-select-option :value="null">Todas as Localidades</b-form-select-option>
                        </b-form-select>
                        <b-form-select v-model="filters.equipamento" :options="equipamentos"
                            placeholder="Filtrar por Equipamento">
                            <b-form-select-option :value="null">Selecione um Equipamento</b-form-select-option>
                        </b-form-select>
                        <b-form-datepicker v-model="filters.dataInicio"
                            placeholder="Data de Início"></b-form-datepicker>
                        <b-form-datepicker v-model="filters.dataFim" placeholder="Data de Fim"></b-form-datepicker>
                    </div>

                    <!-- Botão de aplicar abaixo dos filtros -->
                    <div class="apply-button">
                        <b-button variant="primary" class="apply-btn" @click="applyFilters">Aplicar</b-button>
                    </div>
                </div>
            </b-form-group>
            <b-spinner v-if="loading" />
            <div v-if="!loading">
                <b-card class="mb-3 full-width-card">
                    <b-table striped hover :items="filteredItems" :fields="fields">
                        <template #cell(acao)="data">
                            <b-button variant="success" @click="showDetalhesModal(data.item)">
                                Detalhes
                            </b-button>
                        </template>
                    </b-table>
                </b-card>
            </div>
            <p v-if="error">{{ error }}</p>
        </div>

        <b-modal id="Detalhes-analise-modal" ref="detalhesModal" title="Detalhes da análise">
            <b-form>
                <div v-if="detalhes">
                    <h2>
                        Responsável:
                        {{ detalhes.users && detalhes.users.length > 0 ? detalhes.users[0].name : 'Não identificado' }}
                    </h2>
                    <h2>
                        Equipamento:
                        {{ detalhes.identificador && detalhes.identificador.key ? detalhes.identificador.key : 'erro' }}
                    </h2>
                    <hr>
                    <b-table :items="detalhes.groups"
                        :fields="[{ key: 'type', label: 'Campo' }, { key: 'key', label: 'Dado' }]" striped>
                        <template #cell(type)="data">
                            {{ data.item.type.charAt(0).toUpperCase() + data.item.type.slice(1) }}
                        </template>
                        <template #cell(key)="data">
                            {{ data.item.key }}
                        </template>
                    </b-table>
                </div>
                <p v-else>Nenhum detalhe disponível.</p>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable */
import { request } from '@/services';

export default {
    data() {
        return {
            items: [],
            loading: true,
            error: null,
            analise: {
                nome: '',
                identificador: '',
                empresa: null,
                funcionario: null,
                equipamento: null
            },
            empresas: [],
            funcionarios: [],
            localidades: [],
            equipamentos: [],
            selectedAnaliseId: null,
            detalhes: null,
            fields: [
                { key: 'key', label: 'Chave' },
                { key: 'responsavel', label: 'Responsável' },
                { key: 'created_at', label: 'Data de Criação' },
                { key: 'acao', label: 'Ação' }
            ],
            sortBy: null,
            sortOptions: [
                { value: 'nome', text: 'Nome' },
                { value: 'responsavel', text: 'Responsável' }
            ],
            filters: {
                responsavel: '',
                empresa: null,
                funcionario: null,
                localidade: null,
                equipamento: null,
                dataInicio: null,
                dataFim: null
            },
            showFilters: false,
            cargoGroupKeys: this.$store.state.user.groups
                .filter(group => group.type === "cargo")
                .map(group => group.key)
        };
    },
    computed: {
        isSuperAdmin() {
            return this.cargoGroupKeys.includes("Super Admin");
        },
        isAdmin() {
            return this.isSuperAdmin || this.cargoGroupKeys.some(key => key.endsWith("_admin"));
        },
        filteredItems() {
            let items = [...this.items];
            const formatDate = (date) => {
                const d = new Date(date);
                return new Date(d.setHours(0, 0, 0, 0));
            };
            return items;
        }
    },
    methods: {
        toggleFilters() {
            this.showFilters = !this.showFilters;
        },
        fetchAnalises() {
            this.loading = true;
            request.get('/analises/list', response => {
                if (response.data && response.data.objects) {
                    this.items = response.data.objects.map(analise => ({
                        key: analise.key,
                        responsavel: analise.users[0]?.name || 'Desconhecido',
                        created_at: this.formatarData(analise.created_at),
                        empresa: analise.empresa?.id,
                        localidade: analise.groups.find(group => group.type === 'localidade')?.key || null
                    }));
                } else {
                    this.error = 'Erro ao carregar dados das análises';
                }
                this.loading = false;
            });
        },
        fetchLocalidades() {
            request.get('/localidade/list', response => {
                if (response.data && response.data.localidades) {
                    this.localidades = response.data.localidades.map(localidade => ({
                        value: localidade,
                        text: localidade
                    }));
                } else {
                    this.error = 'Erro ao carregar localidades';
                }
            });
        },
        fetchEmpresas() {
            request.get('/empresas/list', response => {
                this.empresas = response.data?.empresas?.map(empresa => ({
                    value: empresa.id,
                    text: empresa.key
                })) || [];
            });
        },
        fetchEquipamentos() {
            request.get('/equipamento/list', response => {
                this.equipamentos = response.data?.equipamentos?.map(equipamento => ({
                    value: equipamento.id,
                    text: equipamento.nome
                })) || [];
            });
        },
        fetchFuncionarios() {
            if (this.filters.empresa) {
                request.get(`/empresas/funcionarios?empresa_id=${this.filters.empresa}`, response => {
                    this.funcionarios = response.data?.funcionarios?.map(funcionario => ({
                        value: funcionario.id,
                        text: funcionario.name
                    })) || [];
                });
            } else {
                request.get(`/empresas/funcionarios`, response => {
                    this.funcionarios = response.data?.funcionarios?.map(funcionario => ({
                        value: funcionario.id,
                        text: funcionario.name
                    })) || [];
                });
            }
        },
        applyFilters() {
            const params = new URLSearchParams();
            if (this.filters.funcionario) {
                params.append('user_id', this.filters.funcionario);
            }
            if (this.filters.empresa) {
                params.append('empresa', this.filters.empresa);
            }
            if (this.filters.localidade) {
                params.append('localidade', this.filters.localidade);
            }
            if (this.filters.equipamento) {
                params.append('equipamento', this.filters.equipamento);
            }
            if (this.filters.dataInicio) {
                params.append('dataInicio', this.filters.dataInicio);
            }
            if (this.filters.dataFim) {
                params.append('dataFim', this.filters.dataFim);
            }
            this.loading = true;
            request.get(`/analises/list?${params.toString()}`, response => {
                if (response.data && response.data.objects) {
                    this.items = response.data.objects.map(analise => ({
                        key: analise.key,
                        responsavel: analise.users[0]?.name || 'Desconhecido',
                        created_at: this.formatarData(analise.created_at),
                        empresa: analise.empresa?.id,
                        localidade: analise.groups.find(group => group.type === 'localidade')?.key || null
                    }));
                } else {
                    this.error = 'Erro ao carregar dados das análises';
                }
                this.loading = false;
            });
        },
        formatarData(data) {
            const [year, month, day] = data.split('T')[0].split('-');
            return `${day}/${month}/${year}`;
        },
        showDetalhesModal(data) {
            this.$refs.detalhesModal.show();
            request.post('/analise/listbyid', { object_key: data.key }, response => {
                if (response.status === 200) {
                    this.detalhes = response.data;
                }
            });
        }
    },
    mounted() {
        this.fetchAnalises();
        this.fetchEmpresas();
        this.fetchLocalidades();
        this.fetchEquipamentos();
        this.fetchFuncionarios();
    }
};
</script>

<style>
.box {
    padding: 20px;
    background-color: #F5F5F5;
    border-radius: 15px;
}

.filters {
    display: flex;
    gap: 15px;
    margin-top: 15px;
}

.listbox {
    max-width: 200px;
    margin-left: 10px;
}
</style>

<style scoped>
.box {
    padding: 20px;
    background-color: #F5F5F5;
    border-radius: 15px;
}

.filters {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.filters-row {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    max-width: 500px;
}

.filters-row .b-form-select,
.filters-row .b-form-datepicker {
    max-width: 200px;
    margin-bottom: 10px;
}

.apply-button {
    align-self: flex-start;
}
</style>
