<template>
  <div>
    <Menu>
      <transition name="page-transition" mode="out-in">
        <router-view :key="$route.fullPath"></router-view>
      </transition>
    </Menu>
    <div>
      <!-- Modals -->
      <component :is="activeModal" v-bind="payload"></component>
    </div>
  </div>
</template>

<style lang="stylus" scoped>

  // Animation Transition for router-view
  .page-transition-enter, .page-transition-leave-to
      //transform translateX(-30px)
      opacity 0

  .page-transition-enter-active, .page-transition-leave-active
      transition all 0.2s

</style>

<script>
/* eslint-disable */
import Menu from '@/components/static/Menu'

import ModalTrigger from '@/mixins/core/ModalTrigger'

export default {
  name: 'Dashboard', // name option is used for modal emit method in EventBehavior.vue to find Dashboard main instance
  mixins: [ModalTrigger],
  components: {
    Menu // Delete,
  },
  methods: {
    getData() {
      this.$store.state.dashboard.forEach(m => {
        if (m === 'samples') {
          this.$store.state[m].get.urn += this.$store.state.user.id
        }
        if (!this.$store.state[m].get.lazy) {
          this.$store.dispatch(m + '/init', { self: this })
        }
      })
    }
  },
  mounted() {
    // Removido o redirecionamento para /analises
  },
  created() {
    this.getData()
  }
}
</script>
