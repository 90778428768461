<template>
  <b-modal id="funcionarios-modal" ref="funcionariosModal" title="Lista de Equipamentos" @show="carregarEquipamentos">
    <b-list-group>
      <b-list-group-item v-for="equipamento in equipamentos" :key="equipamento.id">
        <b-form-checkbox :value="equipamento.id" v-model="selectedEquipamento" :checked="isChecked(equipamento)"
          @change="atualizarSelecionado(equipamento.id)">
          <label v-if="equipamento.user === usuarioId">
            {{ equipamento.nome }}
          </label>
          <label v-else>
            {{ equipamento.nome }}
          </label>
        </b-form-checkbox>
      </b-list-group-item>
      <b-list-group-item v-if="!equipamentos.length">Nenhum equipamento disponível.</b-list-group-item>
    </b-list-group>
  </b-modal>
</template>

<script>
import { request } from '@/services'
/* eslint-disable */
export default {
  props: {
    funcionarios: {
      type: Array,
      required: true
    },
    empresa: {
      type: String,
      required: true
    },
    usuarioId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      equipamentos: [],
      selectedEquipamento: [], // Inicializando como array
      mensagem: '' // Mensagem para feedback
    }
  },
  watch: {
    empresa: {
      immediate: true,
      handler(novaEmpresa) {
        // Chama a função para carregar equipamentos, se necessário
      }
    }
  },
  methods: {
    carregarEquipamentos() {
      request.get('equipamento/list',
        response => {
          console.log('Resposta da API:', response);
          if (response.status === 200) {
            this.equipamentos = response.data.equipamentos;
            // Ajuste para garantir que o equipamento do usuário seja pré-selecionado
            this.selectedEquipamento = this.equipamentos
              .filter(equipamento => equipamento.user === this.usuarioId)
              .map(equipamento => equipamento.id); // Marca os equipamentos do usuário
            this.mensagem = 'Equipamentos carregados com sucesso!';
          } else {
            this.mensagem = 'Erro ao carregar equipamentos.';
          }
          console.log('Equipamentos recebidos:', this.equipamentos);
        },
        error => {
          console.error('Erro ao carregar equipamentos:', error);
          this.mensagem = 'Erro ao carregar equipamentos.';
        }
      );
    },
    atualizarSelecionado(id) {
      const status = this.selectedEquipamento.includes(id); // Verifica se o ID está no array, indicando se foi marcado ou desmarcado
      this.atualizarEquipamento(id, status);
    },
    atualizarEquipamento(equipamentoId, status) {
      const payload = {
        user_id: this.usuarioId, // ID do funcionário
        equipamento: equipamentoId, // ID do equipamento
        status: status // true se marcado, false se desmarcado
      };

      console.log('Payload enviado:', payload); // Log para depuração

      request.post('equipamento/update', payload,
        response => {
          console.log('Equipamento atualizado com sucesso:', response);
        },
        error => {
          console.error('Erro ao atualizar equipamento:', error);
        }
      );
    },
    isChecked(equipamento) {
      // Retorna true ou false dependendo do estado do equipamento
      return this.selectedEquipamento.includes(equipamento.id);
    }
  }
}
</script>
