<script>/* eslint-disable */</script>
<template>
  <div>
    <h1>Estatísticas</h1>
    <div class="box">
      <b-spinner v-if="loading" />
      <div v-if="!loading">
        <b-card v-for="estatistica in items" :key="estatistica.key">
          <div class="d-flex justify-content-between align-items-left">
            <b-card-text class="block">
              <span>Total de Empresas: {{ estatistica.totalEmpresas }}</span>
              <span>Empresas Ativas: {{ estatistica.empresasAtivas }}</span>
            </b-card-text>

            <b-card-text class="block">
              <span>Total de Funcionários: {{ estatistica.totalFuncionarios }}</span>
              <span>Funcionários Ativos: {{ estatistica.funcionariosAtivos }}</span>
            </b-card-text>

            <b-card-text class="block">
              <span>Total de Equipamentos: {{ estatistica.totalEquipamentos }}</span>
              <span>Equipamentos em Uso: {{ estatistica.equipamentosEmUso }}</span>
            </b-card-text>

            <div class="d-flex">
              <b-button variant="warning" class="mr-2" @click="editarEstatistica(estatistica)">Editar</b-button>
              <b-button variant="danger" class="mr-2" @click="deletarEstatistica(estatistica)">Deletar</b-button>
            </div>
          </div>
        </b-card>
      </div>
      <p v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<style>
.box {
  padding: 10px;
  background-color: #F5F5F5;
  border-radius: 15px;
}
</style>

<style lang="stylus" scoped>
@import '../../../style/colors.styl';
@import '../../../style/mixins.styl';

.btn-show
    float right
    margin-top -15px
    border-radius 32px
    background-color content-theme()
    padding 8px 16px
    color #ffffff
    cursor pointer

.block
    display flex
    text-align left
    align-items left
    flex-direction column
    margin 0
    min-width 20%

.img-holder
    width 50px
    height 50px
    border-radius 8px
    background-color #bbb
</style>

<script>
import Card from '@/components/builders/Card'
import { request } from '@/services'

export default {
  name: 'estatisticas',
  components: { Card },
  data() {
    return {
      items: [],
      loading: true,
      error: null
    }
  },
  mounted() {
    this.fetchEstatisticas();
  },
  methods: {
    fetchEstatisticas() {
      request.get('estatisticas/list', response => {
        this.items = response.data.estatisticas;
        this.loading = false;
      }, error => {
        console.error('Erro ao buscar estatísticas:', error);
        this.error = 'Erro ao carregar estatísticas';
        this.loading = false;
      });
    },
    editarEstatistica(estatistica) {
      this.$router.push(`/estatisticas/edit/${estatistica.id}`);
    },
    deletarEstatistica(estatistica) {
      if (confirm('Tem certeza que deseja deletar esta estatística?')) {
        request.delete(`estatisticas/delete/${estatistica.id}`, response => {
          this.fetchEstatisticas();
        }, error => {
          console.error('Erro ao deletar estatística:', error);
          this.error = 'Erro ao deletar estatística';
        });
      }
    }
  }
}
</script>
