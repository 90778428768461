import { baseMutations, baseActions } from '../functions'

export default {
  namespaced: true,
  state: {
    // Info
    items: [],
    title: 'Nova Empresa',
    name: 'Nova Empresa',
    loading: true,

    // Menu
    menu: {
      title: 'Nova Empresa',
      icon: { key: 'business', custom: true }
    },

    // Routes - Components actions
    actions: {
      // Main Action
      component: 'Cards.nova-empresa',
      props: {
        title: 'Cadastro de Nova Empresa',
        module: 'nova-empresa',
        modelKey: 'empresa'
      }
    },

    // API Get index
    get: {
      urn: 'empresas/list',
      response: 'empresas'
    },

    // API routes
    requests: {
      add: {
        title: 'Cadastrar Nova Empresa',
        success: 'Empresa cadastrada com sucesso',
        urn: 'empresas/create',
        response: 'empresa',
        method: 'post',
        dispatch: 'add'
      }
    },

    // Models
    models: {
      empresa: [
        { key: 'nome', label: 'Nome', type: 'text' },
        { key: 'email', label: 'Email', type: 'email' },
        { key: 'senha', label: 'Senha', type: 'password' },
        { key: 'confirmarSenha', label: 'Confirmar Senha', type: 'password' },
        { key: 'telefone', label: 'Telefone', type: 'text' },
        { key: 'documento', label: 'Documento', type: 'text' },
        { key: 'empresa', label: 'Empresa', type: 'select' }
      ]
    },

    // Forms
    forms: {
      save: [
        { key: 'nome', label: 'Nome', type: 'text', col: 6 },
        { key: 'email', label: 'Email', type: 'email', col: 6 },
        { key: 'senha', label: 'Senha', type: 'password', col: 3 },
        { key: 'confirmarSenha', label: 'Confirmar Senha', type: 'password', col: 3 },
        { key: 'telefone', label: 'Telefone', type: 'text', col: 3, mask: '(##) #####-####' },
        { key: 'documento', label: 'Documento', type: 'text', col: 3, mask: '###.###.###-##|##.###.###/####-##' },
        { key: 'empresa', label: 'Empresa', type: 'select', col: 12, options: 'empresas/list', value: 'key', text: 'key' }
      ]
    }
  },
  mutations: Object.assign({}, baseMutations, {
    // ...
  }),
  actions: Object.assign({}, baseActions, {
    // ...
  })
}
