<script>/* eslint-disable */</script>
<template>
    <FuncionarioProfile v-bind="$props" v-slot="props" :row="1">

        <div class="d-flex justify-content-between align-items-center">

            <div class="img-holder"></div>

            <b-card-text class="block">
                <span>{{ props.item.name }}</span>
                <span>{{ props.item.email }}</span>
            </b-card-text>

            <b-card-text class="block">
                <span>{{ props.item.telephone }}</span>
                <span>{{ props.item.document }}</span>
            </b-card-text>

            <b-card-text class="block">
                <span>{{ props.item.groups[0].key }}</span>
                <span>{{ props.item.groups[0].type }}</span>
            </b-card-text>

            <Buttons :data="props.item"></Buttons>

        </div>

    </FuncionarioProfile>
</template>

<style lang="stylus" scoped>

@import '../../../style/colors.styl';
@import '../../../style/mixins.styl';

.btn-show
    float right
    margin-top -15px
    border-radius 32px
    background-color content-theme()
    padding 8px 16px
    color #ffffff
    cursor pointer

.block
    display flex
    text-align left
    align-items left
    flex-direction column
    margin 0
    min-width 20%

.img-holder
    width 50px
    height 50px
    border-radius 8px
    background-color #bbb

</style>

<script>

import FuncionarioProfile from './FuncionarioProfile.vue'

import Buttons from '@/components/holders/Buttons'

import View from '@/mixins/core/ViewBehavior'
import Action from '@/mixins/core/ActionBehavior'

export default {
    mixins: [View, Action],
    components: { FuncionarioProfile, Buttons },
    props: ['modelKey']
}
</script>
