import { baseMutations, baseActions } from '../functions'

export default {
  namespaced: true,
  state: {
    // Info
    items: [],
    name: 'Grupos',
    loading: true,
    root: true,

    // Menu
    menu: {
      title: 'Grupos',
      icon: { key: 'groups', custom: true }
    },

    // Routes - Components actions
    actions: {
      // Main Action
      component: 'Cards.Basic',
      props: { title: 'groupsicon', module: 'groups', modelKey: 'card' },
      children: [
        {
          key: 'add',
          scope: 'Title',
          event: 'link',
          options: { label: 'Novo Grupo', icon: 'add' },
          component: 'Form',
          props: { title: 'Cadastrar Novo Grupo', module: 'groups', formKey: 'save', requests: 'add.linkrules' }
        },
        {
          key: 'edit',
          scope: 'Buttons',
          event: 'link',
          options: { theme: 'warning' },
          params: ['id'],
          component: 'Form',
          props: route => ({ itemId: route.params.id, title: 'Editar Grupo', module: 'groups', formKey: 'save', requests: 'edit.linkrules' })
        }
      ]
    },

    // API Get index
    get: {
      urn: 'group/index',
      response: 'groups'
    },

    // API routes
    requests: {
      add: {
        title: 'Criar novo Grupo',
        success: 'Grupo Criado com successo',
        urn: 'group/create',
        response: 'group',
        method: 'post',
        dispatch: 'add'
      },
      edit: {
        title: 'Editar Grupo',
        success: 'Grupo Editado com successo',
        urn: 'group/update',
        response: 'group',
        method: 'put',
        dispatch: 'edit'
      },
      linkrules: {
        title: 'Linkar regras com Grupo',
        success: 'Regras linkadas com successo',
        urn: 'group/linkrole',
        response: 'roles',
        method: 'post'
      }
    },

    // Models
    models: {
      card: [
        { key: 'key', label: 'Chave', slot: 'title' },
        { key: 'description', label: 'Descrição', slot: 'body' }
      ]
    },

    // Forms
    forms: {
      save: [
        { key: 'group_id', type: 'hidden', from: 'item', value: 'id' },
        { key: 'key', label: 'Chave', type: 'text', col: 3 },
        { key: 'type', label: 'Tipo', type: 'text', col: 3 },
        { key: 'description', label: 'Descrição', type: 'text', col: 6 },
        { key: 'master_group_id', label: 'Grupo Pai', type: 'select', from: 'store', value: 'groups.items', options: { value: 'id', text: 'key' }, col: 12 },

        { key: 'group_id', type: 'response', value: 'id', from: 0, depth: 1 },
        {
          key: 'roles_ids',
          type: 'view',
          view: 'Table',
          props: { noTitle: true, module: 'rules', modelKey: 'fields', propActions: [], selectable: true },
          formatter: v => v.reduce((a, o) => [...a, o.id], []),
          depth: 1
        }
      ]
    }

  },
  mutations: Object.assign({}, baseMutations, {
    // ...
  }),
  actions: Object.assign({}, baseActions, {
    // ...
  })
}
