import Vue from 'vue'
import Vuex from 'vuex'
import { api } from '@/services'

// Modules
import Samples from './modules/samples'
import SampleImgs from './modules/images'
import Classificar from './modules/classificar'

// Importar todos os dados dos modulos criados para cada pagina no menu lateral

// Modules Adm
import Groups from './modules/groups'
import Rules from './modules/rules'
import Users from './modules/users'
import AtivesUser from './modules/ativesUser'
import Atives from './modules/atives'
import Employees from './modules/employees'
import Empresas from './modules/empresas'
import Equipamentos from './modules/equipamentos'
import Estatisticas from './modules/estatisticas'
import NovaEmpresa from './modules/nova-empresa'
import Analises from './modules/analises'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.user || '{}'),
    token: localStorage.token || undefined,
    // Os itens devem ser adicionado a lista pra ser exebido na no menu lateral
    dashboard: ['analises', 'groups', 'rules', 'users', 'ativesUser', 'atives', 'samples', 'images', 'classificar', 'employees', 'empresas', 'equipamentos', 'estatisticas'] // active module
  },
  getters: {
    userName: state => {
      return state.user.name === undefined ? '' : state.user.name
    },
    isLogged: state => Object.keys(state.user).length !== 0 && state.token !== undefined
  },
  mutations: {
    login: (state, payload) => {
      state.user = payload.user
      state.token = payload.token
    },
    logout: state => {
      state.user = {}
      state.token = undefined
    }
  },
  actions: {
    login: (context, payload) => {
      localStorage.user = JSON.stringify(payload.user)
      localStorage.token = payload.token
      api.defaults.headers.common.Authorization = `Bearer ${payload.token}`
      context.commit('login', payload)
    },
    logout: context => {
      localStorage.clear()
      delete api.defaults.headers.common.Authorization
      context.commit('logout')
    }
  },
  // Adicionar o modulo com seus respectivos nomes que sera importado no menu lateral
  modules: {
    groups: Groups,
    samples: Samples,
    images: SampleImgs,
    classificar: Classificar,
    rules: Rules,
    users: Users,
    ativesUser: AtivesUser,
    atives: Atives,
    employees: Employees,
    empresas: Empresas,
    equipamentos: Equipamentos,
    estatisticas: Estatisticas,
    'nova-empresa': NovaEmpresa,
    analises: Analises
  }
})
