<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h1>Equipamentos</h1>
      <span v-if="getGroupKeyByType() === 'Super Admin'">
        <b-button variant="primary" class="mb-3" @click="showModal">Novo Equipamento</b-button>
      </span>
    </div>

    <div class="box">
      <!-- Filtro de ordenação -->
      <div class="mb-3">
        <dv class="flexline">
          <h2>Filtrar</h2>
          <b-form-select v-model="sortOption" @change="applyFilter" :options="sortOptions" class="listbox" />
        </dv>
      </div>
      <b-spinner v-if="loading" />
      <div v-if="!loading">
        <!-- Cabeçalhos da tabela -->
        <b-table :items="sortedItems" :fields="fields" striped hover responsive>
          <template #cell(created_at)="data">
            <span>{{ formatarData(data.item.created_at) }}</span>
          </template>

          <!-- Verificando o campo Identificador -->
          <template #cell(identificador)="data" v-if="getGroupKeyByType() === 'Super Admin'">
            <span>{{ data.item.key || 'Não disponível' }}</span>
          </template>

          <!-- Verificando o campo Ações -->
          <template #cell(actions)="data" v-if="getGroupKeyByType() === 'Super Admin'">
            <b-button variant="success" @click="showEmpresasModal(data.item.id, data.item.empresa)">Detalhes</b-button>
          </template>
        </b-table>
      </div>
      <p v-if="error">{{ error }}</p>
    </div>

    <b-modal id="equipamento-modal" ref="modal" title="Novo Equipamento" @ok="registerEquipamento">
      <b-form @submit.stop.prevent="registerEquipamento">
        <!-- Primeira linha: nome e identificador -->
        <b-row>
          <b-col md="6">
            <b-form-group label="Nome">
              <b-form-input v-model="equipamento.nome" type="text" required
                placeholder="Digite o nome do equipamento"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="getGroupKeyByType() === 'Super Admin'">
            <b-form-group label="Identificador">
              <b-form-input v-model="equipamento.identificador" type="text" required
                placeholder="Digite o identificador"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Segunda linha: empresa e funcionário -->
        <b-row>
          <b-col md="6">
            <b-form-group label="Empresa">
              <b-form-select v-model="equipamento.empresa" :options="empresas" required @change="fetchFuncionarios">
                <template #first>
                  <b-form-select-option :value="null">Selecione uma empresa</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="equipamento.empresa">
            <b-form-group label="Funcionário">
              <b-form-select v-model="equipamento.funcionario" :options="funcionarios"></b-form-select>
              <p v-if="funcionarios.length === 0">Nenhum funcionário disponível.</p>
              <!-- Mensagem se não houver funcionários -->
            </b-form-group>
          </b-col>
        </b-row>

        <b-button type="submit" variant="primary">Salvar</b-button>
      </b-form>
    </b-modal>

    <EmpresasModal :empresas="empresas" :equipamento-id="selectedEquipamentoId" :empresa-equipamento="selectedEmpresaId"
      ref="empresasModal" />
  </div>
</template>

<script>
/* eslint-disable */
import { request } from '@/services';
import EmpresasModal from '@/components/modals/EmpresasModal.vue';

export default {
  components: {
    EmpresasModal
  },
  data() {
    return {
      items: [],
      loading: true,
      error: null,
      equipamento: {
        nome: '',
        identificador: '',
        empresa: null,
        funcionario: null
      },
      empresas: [],
      funcionarios: [],
      selectedEquipamentoId: null,
      selectedEmpresaId: null,
      fields: [
        { key: 'nome', label: 'Nome' },
        ...(this.getGroupKeyByType() === 'Super Admin' ? [{ key: 'identificador', label: 'Identificador' }, { key: 'actions', label: 'Ações' }] : []), // Condição para as colunas
        { key: 'created_at', label: 'Data de Cadastro' }
      ],
      sortOption: 'date', // Valor inicial para ordenar por data
      sortOptions: [
        { value: 'date', text: 'Ordenar por Data' },
        { value: 'name', text: 'Ordenar por Nome' }
      ]
    };
  },
  computed: {
    sortedItems() {
      // Retorna os itens ordenados conforme a seleção do filtro
      return this.items.sort((a, b) => {
        if (this.sortOption === 'date') {
          return new Date(b.created_at) - new Date(a.created_at); // Ordena por data de forma decrescente
        } else if (this.sortOption === 'name') {
          return a.nome.localeCompare(b.nome); // Ordena por nome de forma alfabética
        }
      });
    }
  },
  mounted() {
    this.fetchEquipamentos();
    this.fetchEmpresas();
  },
  methods: {
    fetchEquipamentos() {
      request.get('equipamento/list', response => {
        this.items = response.data.equipamentos;
        this.loading = false;
      }, error => {
        this.error = 'Erro ao carregar equipamentos';
        this.loading = false;
      });
    },
    fetchEmpresas() {
      request.get('empresas/list', response => {
        if (Array.isArray(response.data.empresas)) {
          this.empresas = response.data.empresas.map(empresa => ({ value: empresa.id, text: empresa.key }));
        } else {
          console.error('Estrutura de dados inesperada:', response.data);
        }
      }, error => {
        console.error('Erro ao buscar empresas:', error);
      });
    },
    fetchFuncionarios() {
      if (this.equipamento.empresa) {
        request.get(`empresas/funcionarios?empresa_id=${this.equipamento.empresa}`, response => {
          console.log('Funcionários recebidos:', response.data); // Log da resposta completa
          if (Array.isArray(response.data.funcionarios)) {
            this.funcionarios = response.data.funcionarios.map(funcionario => ({
              value: funcionario.id,
              text: funcionario.name
            }));
          } else {
            console.error('Estrutura de dados inesperada:', response.data);
            this.funcionarios = [];
          }
        }, error => {
          console.error('Erro ao buscar funcionários:', error);
          this.funcionarios = [];
        });
      } else {
        this.funcionarios = [];
      }
    },
    showModal() {
      this.$refs.modal.show();
    },
    showEmpresasModal(equipamentoId, empresaId) {
      if (equipamentoId) {
        this.selectedEquipamentoId = equipamentoId;
        this.selectedEmpresaId = empresaId;
        this.$bvModal.show('empresas-modal');
      } else {
        console.error('ID do equipamento é nulo');
      }
    },
    registerEquipamento() {
      request.post('equipamento/register', this.equipamento, response => {
        if (response.status === 200) {
          this.$refs.modal.hide();
          this.$bvToast.toast('Equipamento registrado com sucesso!', {
            title: 'Sucesso',
            variant: 'success',
            solid: true
          });
          this.fetchEquipamentos();
        } else {
          this.$bvToast.toast('Erro interno do servidor.', {
            title: 'Erro',
            variant: 'danger',
            solid: true
          });
        }
      }, error => {
        this.$bvToast.toast('Erro interno do servidor.', {
          title: 'Erro',
          variant: 'danger',
          solid: true
        });
        console.error('Erro ao registrar equipamento:', error);
      });
    },
    formatarData(data) {
      const [year, month, day] = data.split('T')[0].split('-');
      return `${day}/${month}/${year}`;
    },
    applyFilter() {
      // Este método é chamado quando o filtro é alterado
      // A ordenação já é feita automaticamente pela computed property 'sortedItems'
    },
    getGroupKeyByType() {
      const cargoGroupKeys = this.$store.state.user.groups
        .filter(group => group.type === "cargo")
        .map(group => group.key)
      console.log({ "cargo gro": cargoGroupKeys });
      return cargoGroupKeys[0];
    }
  }
}
</script>

<style scoped>
.box {
  padding: 20px;
  background-color: #F5F5F5;
  border-radius: 15px;
  border-block-color: none;
}

.flexline {
  display: flex;
  flex-direction: row;
}

.flexline > h2 {
  margin-right: 10px;
}

.listbox {
  max-width: 200px;
}
</style>
