<template>
    <div>
        <div class="menu-top">
            <div class="icon">
                <i @click="toggleMenu" class="menu-mobile-icon material-icons">menu</i>
                <img class="logo-menu" alt="Agritest Logo" @click="$router.push('/')"
                    src="@/assets/images/logo-agritest.svg">
            </div>
            <div class="after-icon">
                <div class="profile" v-b-modal.modal-multi-1>
                    <div class="profile-btn">
                        <img class="profile-image" src="@/assets/images/Picture.png">
                        <b-modal body-class="my-class" id="modal-multi-1" size="md" centered no-stacking hide-header
                            hide-footer>
                            <div class="d-block text-center mt-1 infos" style="margin: 20px;">
                                <h4>{{ this.$store.state.user.name }}</h4>
                                <h6>{{ this.$store.state.user.email }}</h6>
                            </div>
                            <b-button class="mt-2 edit" variant="ligth" block @click="edit">Editar Perfil</b-button>
                            <b-button class="mt-2 logout" variant="ligth" block @click="logout">Sair</b-button>
                        </b-modal>
                    </div>
                    <div class="profile-info">
                        <h class="name">{{ this.$store.state.user.name }}</h>
                        <h class="email">{{ this.$store.state.user.email }}</h>
                    </div>
                </div>
            </div>
        </div>

        <div class="menu-aside">
            <div ref="menu" class="bottom-icon menu-deactivate">
                <router-link v-for="tab in tabs" :key="tab.key" :to="{ name: tab.key }" tag="div"
                    active-class="current-page" :exact="tab.root">
                    <div class="menu-item">
                        <!-- Custom icons -->
                        <i v-if="tab.icon.custom" :class="'menu-icon icon-custom-' + tab.icon.key"></i>

                        <!-- Material Design -->
                        <i v-else class="menu-icon material-icons-outlined">{{ tab.icon.key }}</i>

                        <span>{{ tab.title }}</span>
                    </div>

                </router-link>

            </div>

            <div class="slot-page">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<style lang="stylus" scoped>

    @import '../../style/colors.styl'
    @import '../../style/dimens.styl'
    @import '../../style/fonts.styl'
    @import '../../style/mixins.styl'

    menuTopHeight = 87px
    menuTopHeightMobile = 63px

    heightContent = calc(100vh - 87px)
    heightContentMobile = calc(100vh - 64px)

    .edit, .logout
        border-color gray
        border-radius 50px
        color gray

        &:hover
            color white
            background-color primary

    .menu-top
        position fixed
        width 100%
        top 0
        z-index 10
        box-shadow 0 0.5px 10px gray

    .menu-top,
    .menu-aside
        display flex
        background-color secondary

    .logo-menu
        cursor pointer
        height 2.8rem
        margin 5px 0

    .icon
        background-color secondary
        padding-bottom 8px
        padding-top 8px
        flex-shrink 0
        transition 0.4s
        width menu-width

        img
            min-width 100%

        .menu-mobile-icon
            margin-left 5px
            font-size 35px
            display none
            vertical-align middle

    .bottom-icon
        background-color white

    .after-icon
        width 100%

    .profile-info
        margin-left 15px
        display grid
        font-family Roboto-Medium
        color black

        .name
          font-size: medium

        .email
          font-size: small

    .profile-btn
        display flex
        align-items center

    h6
        color primary
        font-family Rubik-Medium

    h4
        color black
        font-family Rubik-Regular

    .slot-page
        top menuTopHeight
        min-height "calc(100vh - %s)" % menuTopHeight

    .bottom-icon
        top menuTopHeight + 40px
        min-height "calc(100vh - %s)" % menuTopHeight

    .slot-page
        position absolute
        left menu-width
        width "calc(100% - %s)" % menu-width
        min-height "calc(100vh - %s)" % menuTopHeight
        padding 25px 50px 0px 50px
        z-index 1 // Adicionando um índice Z para garantir que fique acima do menu-aside

    .bottom-icon
        position fixed
        width menu-width
        left 0
        flex-shrink 0
        text-align left
        align-items center

        > div
            padding 14px 0 0 0
            cursor pointer
            transition 0.3s
            height 65.1px

            &:hover
                background-color content-theme()

        .current-page
            .menu-icon, span
                color primary

        .menu-item
            nowrap()
            display inline-block
            align-items center

        .menu-icon, span
            display inline
            text-align center
            color color-theme(true)
            width 100%
            no-select()

        .menu-icon
            font-size 28px
            padding 0 16px 0 50px

        span
            font-size 14px
            font-family Manrope-Bold

    .after-icon
        display flex
        align-items center

        .profile
            color primary
            margin 0 85px 0 auto
            order 2
            display flex
            cursor pointer

    // Mobile View
    @media (max-width: 768px)

        .bottom-icon
            width 40%
            min-width 180px
            z-index 10

        .slot-page
            left 0
            width unset

        .after-icon
            .profile
                color white
                margin 5px 10px 5px auto
                order 2

        .menu-aside
            display block

            div
                transition all 350ms ease-out

            span, i
                transition all 250ms ease-in-out

            .menu-deactivate
                min-width 0
                width 0

                div *
                    font-size 0
                    height 0

        .menu-top
            .icon
                width unset
                cursor pointer

                > img
                    min-width unset
                    padding-left 24px

                .menu-mobile-icon
                    display inline

        .profile-info
          margin 0 40px 0 10px

    // Remove icon
    @media (max-width: 576px)
        .icon > img
            display none

        .slot-page,
        .bottom-icon
            top menuTopHeightMobile
            min-height "calc(100vh - %s)" % menuTopHeightMobile

        .profile-info
          margin 0 20px 0 10px

          .name
            font-size: 0.9rem

          .email
            font-size: 0.7rem

        .profile-image
            height: 2em

</style>

<script>

import { request } from '@/services'
/* eslint-disable */
export default {
    computed: {
        tabs() {
            const cargoGroupKeys = this.$store.state.user.groups
                .filter(group => group.type === "cargo")
                .map(group => group.key)
            let menuItens = [];

            // Verificar o cargo do usuário e definir os itens do menu
            if (cargoGroupKeys[0] == 'Super Admin') {
                menuItens = ['analises', 'rules', 'ativesUser', 'atives', 'samples', 'images', 'classificar', 'employees', 'empresas', 'equipamentos', 'estatisticas']
            } else if (cargoGroupKeys[0].endsWith('_admin')) {
                menuItens = ['analises', 'rules', 'atives', 'samples', 'images', 'classificar', 'employees', 'equipamentos', 'estatisticas']
            } else if (cargoGroupKeys[0].endsWith('_funcionario')) {
                menuItens = ['analises', 'rules', 'ativesUser', 'atives', 'samples', 'images', 'classificar', 'equipamentos']
            }

            // Agora, cria a lista de tabs a partir dos itens selecionados
            const tabs = [];
            menuItens.forEach(item => {
                const state = this.$store.state[item.toLowerCase()];  // A chave do estado deve ser minúscula
                if (state && !state.noMenu) {
                    tabs.push(Object.assign({}, state.menu, { key: item.toLowerCase(), root: state.root }));
                }
            });

            return tabs;
        }
    },
    methods: {
        getTabs() {
            const tabs = [];
            this.$store.state.dashboard.forEach(module => {
                const state = this.$store.state[module];
                if (!state.noMenu) {
                    tabs.push(Object.assign({}, state.menu, { key: module, root: state.root }));
                }
            });
            return tabs;
        },
        logout() {
            const disconnect = e => {
                this.$store.dispatch('logout')
                this.$router.replace({ name: 'Login' })
            }

            // even on failure, logout anyway, token may have broken
            request.get('auth/logout', disconnect, disconnect)
        },
        toggleMenu() {
            this.$refs.menu.classList.toggle('menu-deactivate')
        },
        edit() {
            this.$router.replace({ name: 'Edit', params: { userId: this.$store.state.user.id } })
        }
    }
}
</script>
