<script>/* eslint-disable */</script>
<template>
  <div>
    <h1>Nova Empresa</h1>
    <div class="box">
      <b-form @submit.prevent="onSubmit">
        <!-- Primeira linha: nome e email -->
        <b-row>
          <b-col md="6">
            <b-form-group label="Nome">
              <b-form-input
                v-model="form.nome"
                type="text"
                required
                placeholder="Digite o nome"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Email">
              <b-form-input
                v-model="form.email"
                type="email"
                required
                placeholder="Digite o email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Segunda linha: senha, confirmar senha, telefone e documento -->
        <b-row>
          <b-col md="3">
            <b-form-group label="Senha">
              <b-form-input
                v-model="form.senha"
                type="password"
                required
                placeholder="Digite a senha"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Confirmar Senha">
              <b-form-input
                v-model="form.confirmarSenha"
                type="password"
                required
                placeholder="Confirme a senha"
                :state="senhasConferem"
              ></b-form-input>
              <b-form-invalid-feedback :state="senhasConferem">
                As senhas não conferem
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Telefone">
              <b-form-input
                v-model="form.telefone"
                type="text"
                required
                placeholder="(99) 99999-9999"
                @input="formatarTelefone"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Documento">
              <b-form-input
                v-model="form.documento"
                type="text"
                required
                placeholder="CPF ou CNPJ"
                @input="formatarDocumento"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Terceira linha: empresa -->
        <b-row>
          <b-col md="12">
            <b-form-group label="Empresa">
              <b-form-select
                v-model="form.empresa"
                :options="empresas"
                required
                value-field="key"
                text-field="key"
              >
                <template #first>
                  <b-form-select-option :value="null">Selecione uma empresa</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-button type="submit" variant="primary" :disabled="!formValido">Salvar</b-button>
      </b-form>
    </div>
  </div>
</template>

<style>
.box {
  padding: 20px;
  background-color: #F5F5F5;
  border-radius: 15px;
}
</style>

<script>
import { request } from '@/services'

export default {
  name: 'nova-empresa',
  data() {
    return {
      form: {
        nome: '',
        email: '',
        senha: '',
        confirmarSenha: '',
        telefone: '',
        documento: '',
        empresa: null
      },
      empresas: [],
      loading: false,
      error: null
    }
  },
  computed: {
    senhasConferem() {
      return this.form.senha === this.form.confirmarSenha
    },
    formValido() {
      return this.form.nome &&
        this.form.email &&
        this.form.senha &&
        this.senhasConferem &&
        this.form.telefone &&
        this.form.documento &&
        this.form.empresa
    }
  },
  mounted() {
    this.fetchEmpresas()
  },
  methods: {
    formatarTelefone(value) {
      let telefone = value.replace(/\D/g, '')
      if (telefone.length <= 11) {
        telefone = telefone.replace(/^(\d{2})(\d)/g, '($1) $2')
        telefone = telefone.replace(/(\d{5})(\d)/, '$1-$2')
        this.form.telefone = telefone
      }
    },
    formatarDocumento(value) {
      let doc = value.replace(/\D/g, '')
      if (doc.length <= 11) {
        // CPF
        doc = doc.replace(/(\d{3})(\d)/, '$1.$2')
        doc = doc.replace(/(\d{3})(\d)/, '$1.$2')
        doc = doc.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
      } else {
        // CNPJ
        doc = doc.replace(/^(\d{2})(\d)/, '$1.$2')
        doc = doc.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
        doc = doc.replace(/\.(\d{3})(\d)/, '.$1/$2')
        doc = doc.replace(/(\d{4})(\d)/, '$1-$2')
      }
      this.form.documento = doc
    },
    fetchEmpresas() {
      this.loading = true
      request.get('empresas/list', response => {
        this.empresas = response.data.empresas
        this.loading = false
      }, error => {
        console.error('Erro ao buscar empresas:', error)
        this.error = 'Erro ao carregar empresas'
        this.loading = false
      })
    },
    onSubmit() {
      if (this.formValido) {
        // Aqui você implementa a lógica de envio do formulário
        console.log('Formulário enviado:', this.form)
      }
    }
  }
}
</script>
