<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h1>Funcionários</h1>
      <b-button variant="primary" @click="showModal">Cadastrar Funcionário</b-button>
    </div>
    <!-- Tabela de Funcionários -->
    <div class="box">
      <!-- Filtro por Nome ou Empresa -->
      <div class="mb-3">
        <b-form-group label="">
          <div class="flexline">
            <h2>Filtrar</h2>
            <b-form-select v-model="sortBy" :options="sortOptions" @change="applySort" class="listbox"></b-form-select>
          </div>
        </b-form-group>
      </div>
      <b-spinner v-if=" loading" />
      <div v-if="!loading">
        <b-table :items="sortedItems" :fields="fields" striped hover responsive>
          <template v-slot:cell(name)="data">
            <span>{{ data.item.name }}</span>
          </template>
          <template v-slot:cell(empresa)="data">
            <span>{{ data.item.empresa }}</span>
          </template>
          <template v-slot:cell(actions)="data">
            <b-button variant="success" class="mr-2" @click="analisarFuncionario(data.item.id)">Análises</b-button>
            <b-button variant="success" class="mr-2"
              @click="showFuncionariosModal(data.item.id, data.item.id)">Equipamentos</b-button>
          </template>
        </b-table>
      </div>
      <p v-if="error">{{ error }}</p>
    </div>

    <!-- Modal para cadastro de funcionário -->
    <Modal name="cadastroFuncionario" title="Novo Funcionário" :onlyBody="true">
      <template v-slot:default>
        <div class="box">
          <b-form @submit.prevent="onSubmit">
            <!-- Primeira linha: nome e email -->
            <b-row>
              <b-col md="6">
                <b-form-group label="Nome">
                  <b-form-input v-model="form.nome" type="text" required placeholder="Digite o nome"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Email">
                  <b-form-input v-model="form.email" type="email" required placeholder="Digite o email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Segunda linha: senha, confirmar senha, telefone e documento -->
            <b-row>
              <b-col md="3">
                <b-form-group label="Senha">
                  <b-form-input v-model="form.senha" type="password" required
                    placeholder="Digite a senha"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Confirmar Senha">
                  <b-form-input v-model="form.confirmarSenha" type="password" required placeholder="Confirme a senha"
                    :state="senhasConferem"></b-form-input>
                  <b-form-invalid-feedback :state="senhasConferem">
                    As senhas não conferem
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Telefone">
                  <b-form-input v-model="form.telefone" type="text" required placeholder="(99) 99999-9999"
                    @input="formatarTelefone"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Documento">
                  <b-form-input v-model="form.documento" type="text" required placeholder="CPF ou CNPJ"
                    @input="formatarDocumento"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Terceira linha: cep, endereço -->
            <b-row>
              <b-col md="6">
                <b-form-group label="CEP">
                  <b-form-input v-model="form.cep" type="text" required placeholder="CEP"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Endereço">
                  <b-form-input v-model="form.endereco" type="text" required placeholder="Endereço"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Quarta linha: complemento, UF, cidade -->
            <b-row>
              <b-col md="4">
                <b-form-group label="Complemento">
                  <b-form-input v-model="form.complemento" type="text" placeholder="Complemento"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="UF">
                  <b-form-input v-model="form.uf" type="text" required placeholder="UF"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Cidade">
                  <b-form-input v-model="form.cidade" type="text" required placeholder="Cidade"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Quinta linha: bairro, razão social -->
            <b-row>
              <b-col md="6">
                <b-form-group label="Bairro">
                  <b-form-input v-model="form.bairro" type="text" required placeholder="Bairro"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Razão Social">
                  <b-form-input v-model="form.razao_social" type="text" required
                    placeholder="Razão Social"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Última linha: empresa -->
            <b-row>
              <b-col>
                <b-form-group label="Empresa">
                  <b-form-select v-model="form.empresa" :options="empresas" required
                    placeholder="Selecione a empresa"></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-button type="submit" variant="primary" :disabled="!formValido">Salvar</b-button>
          </b-form>
        </div>
      </template>
    </Modal>
    <!-- Modal para Funcionários -->
    <FuncionariosModal :funcionarios="empresas" :empresa="selectedEquipamentoId" :usuarioId="selectedFuncionarioId" />

  </div>
</template>

<script>
/* eslint-disable */
import { request } from '@/services'; // Corrigindo o caminho para o módulo services
import Modal from '../../static/Modal.vue' // Importando o componente Modal
import FuncionariosModal from '../../modals/FuncionariosModal.vue' // Importando o modal de Funcionários
/* eslint-disable */

export default {
  components: {
    Modal,
    FuncionariosModal
  },
  data() {
    return {
      items: [],
      loading: true,
      error: null,
      form: {
        nome: '',
        email: '',
        senha: '',
        confirmarSenha: '',
        telefone: '',
        documento: '',
        empresa: '',
        cep: '',
        endereco: '',
        complemento: '',
        uf: '',
        cidade: '',
        bairro: '',
        razao_social: ''
      },
      empresas: [],
      selectedEquipamentoId: null, // Adicionando a variável para armazenar o ID do equipamento
      selectedFuncionarioId: null, // Adicionando a variável para armazenar o ID do funcionário
      fields: [
        { key: 'name', label: 'Nome', sortable: true },  // Adicionado sortable para ordenar por Nome
        { key: 'empresa', label: 'Empresa', sortable: true },  // Adicionado sortable para ordenar por Empresa
        { key: 'actions', label: 'Ações', sortable: false }
      ],
      sortBy: 'name',  // Default sorting option
      sortOptions: [
        { value: 'name', text: 'Nome' },
        { value: 'empresa', text: 'Empresa' }
      ]
    }
  },
  computed: {
    senhasConferem() {
      return this.form.senha === this.form.confirmarSenha;
    },
    formValido() {
      return this.form.nome &&
        this.form.email &&
        this.form.senha &&
        this.senhasConferem &&
        this.form.telefone &&
        this.form.documento &&
        this.form.empresa &&
        this.form.cep &&
        this.form.endereco &&
        this.form.uf &&
        this.form.cidade &&
        this.form.bairro &&
        this.form.razao_social;
    },
    // Computed property to handle sorting dynamically
    sortedItems() {
      const sorted = this.items.slice();

      // Sort by the selected property (name or empresa)
      sorted.sort((a, b) => {
        const valueA = this.sortBy === 'name' ? a.name : a.empresa;
        const valueB = this.sortBy === 'name' ? b.name : b.empresa;
        return valueA.localeCompare(valueB);  // Sort alphabetically
      });

      return sorted;
    }
  },
  mounted() {
    this.fetchFuncionarios();
    this.fetchEmpresas();
  },
  methods: {
    fetchFuncionarios() {
      request.get('empresas/funcionarios', response => {
        // Formata a data de cada item
        this.items = response.data.funcionarios.map(item => {
          item.created_at = this.formatarData(item.created_at); // Formata a data aqui
          return item;
        });
        this.loading = false;
      }, error => {
        console.error('Erro ao buscar funcionários:', error);
        this.error = 'Erro ao carregar funcionários';
        this.loading = false;
      });
    },
    fetchEmpresas() {
      this.loading = true;
      request.get('empresas/list', response => {
        this.empresas = response.data.empresas.map(empresa => ({
          value: empresa.id,
          text: empresa.key // Corrigido para usar 'name' em vez de 'nome'
        }));
        this.loading = false;
      }, error => {
        console.error('Erro ao buscar empresas:', error);
        this.error = 'Erro ao carregar empresas';
        this.loading = false;
      });
    },
    // Método para formatar a data no formato DD/MM/YYYY
    formatarData(data) {
      const date = new Date(data); // Converte a string de data em objeto Date
      const day = String(date.getDate()).padStart(2, '0'); // Dia com 2 dígitos
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês com 2 dígitos
      const year = date.getFullYear(); // Ano

      return `${day}/${month}/${year}`; // Retorna no formato DD/MM/YYYY
    },
    formatarTelefone(value) {
      let telefone = value.replace(/\D/g, '');
      if (telefone.length <= 11) {
        telefone = telefone.replace(/^(\d{2})(\d)/g, '($1) $2');
        telefone = telefone.replace(/(\d{5})(\d)/, '$1-$2');
        this.form.telefone = telefone;
      }
    },
    formatarDocumento(value) {
      let doc = value.replace(/\D/g, '');
      if (doc.length <= 11) {
        doc = doc.replace(/(\d{3})(\d)/, '$1.$2');
        doc = doc.replace(/(\d{3})(\d)/, '$1.$2');
        doc = doc.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
      } else {
        doc = doc.replace(/^(\d{2})(\d)/, '$1.$2');
        doc = doc.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
        doc = doc.replace(/\.(\d{3})(\d)/, '.$1/$2');
        doc = doc.replace(/(\d{4})(\d)/, '$1-$2');
      }
      this.form.documento = doc;
    },
    onSubmit() {
      if (this.formValido) {
        const payload = {
          name: this.form.nome,
          email: this.form.email,
          password: this.form.senha,
          password_confirmation: this.form.confirmarSenha,
          document: this.form.documento,
          cep: this.form.cep,
          endereco: this.form.endereco,
          complemento: this.form.complemento,
          uf: this.form.uf,
          cidade: this.form.cidade,
          bairro: this.form.bairro,
          razao_social: this.form.razao_social,
          empresa: this.form.empresa // Corrigido para usar o ID da empresa
        };
        request.post('/register', payload, response => {
          if (response.status === 200) {
            this.$bvModal.hide('cadastroFuncionario-modal');
            this.fetchFuncionarios(); // Atualiza a lista após o cadastro
          }
        });
      }
    },
    showModal() {
      this.$bvModal.show('cadastroFuncionario-modal');
    },
    showFuncionariosModal(id, usuarioId) {
      console.log('ID do funcionário:', usuarioId); // Log para verificar o ID do funcionário
      this.selectedEquipamentoId = id; // Armazena o ID do equipamento selecionado
      this.selectedFuncionarioId = usuarioId; // Armazena o ID do funcionário
      this.$bvModal.show('funcionarios-modal', {
        props: {
          usuarioId: usuarioId // Passando o usuarioId como prop
        }
      });
    },
    analisarFuncionario(id) {
      this.$router.push({ path: '/analises', query: { user_id: id } });
    },
    equiparFuncionario(id) {
      console.log('Equipar funcionário', id);
    },
    // Método chamado quando há alteração no sortBy
    applySort() {
      // Este método já é tratado pela computed property sortedItems
    }
  }
}
</script>

<style>
.box {
  padding: 10px;
  background-color: #F5F5F5;
  border-radius: 15px;
}

.flexline {
  display: flex;
  flex-direction: row;
}

.flexline>h2 {
  margin-right: 10px;
}

.listbox {
  max-width: 200px;
}
</style>
