<template>
  <b-modal id="empresas-modal" ref="empresasModal" title="Lista de Empresas">
    <b-list-group>
      <b-list-group-item v-for="empresa in empresas" :key="empresa.text" class="d-flex justify-content-between align-items-right">
        <b-form-checkbox :checked="selectedEmpresa === empresa.text" @change="handleCheckboxChange(empresa.text)" class="mr-2">{{ empresa.text }}</b-form-checkbox>
      </b-list-group-item>
    </b-list-group>
  </b-modal>
</template>

<script>
import { request } from '@/services' // Corrigindo a importação
/* eslint-disable */
export default {
  props: {
    empresas: {
      type: Array,
      required: true
    },
    equipamentoId: {
      type: String,
      required: true
    },
    empresaEquipamento: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      selectedEmpresa: null,
      selectedEquipamentos: [] // Adicionando a lista de equipamentos selecionados
    }
  },
  watch: {
    empresaEquipamento: {
      immediate: true,
      handler(newVal) {
        this.selectedEmpresa = newVal || null; // Define como null se não houver correspondência
      }
    }
  },
  methods: {
    handleCheckboxChange(empresaId) {
      const index = this.selectedEquipamentos.indexOf(empresaId);
      if (index === -1) {
        this.selectedEquipamentos.push(empresaId); // Adiciona se não estiver selecionado
      } else {
        this.selectedEquipamentos.splice(index, 1); // Remove se já estiver selecionado
      }
      this.selectedEmpresa = empresaId;
      console.log({"empresa selecionada": empresaId});
      this.updateEquipamento(empresaId);
    },
    updateEquipamento(empresaId) {
      const status = this.selectedEquipamentos.includes(empresaId); // Corrigindo a lógica para usar empresaId
      request.post('equipamento/update', {
        equipamento: this.equipamentoId,
        empresa: {
          empresa: empresaId,
          status: status
        }
      }, response => {
        this.$bvToast.toast('Equipamento atualizado com sucesso!', {
          title: 'Sucesso',
          variant: 'success',
          solid: true
        });
      }, error => {
        this.$bvToast.toast('Erro ao atualizar equipamento.', {
          title: 'Erro',
          variant: 'danger',
          solid: true
        });
        console.error('Erro ao atualizar equipamento:', error);
      });
    }
  },
  mounted() {
    if (this.empresaEquipamento) {
      this.selectedEmpresa = this.empresaEquipamento; // Seleciona automaticamente a empresa se existir
    }
    console.log({"selectedEmpresa": this.selectedEmpresa}); // Log para verificar o valor inicial
  }
}
</script>
