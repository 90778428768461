import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
/* eslint-disable */
// Dashboard
import Dashboard from '../views/Dashboard'

// Components
import Table from '../components/views/Table'
import OutputsSettingsTable from '../components/views/OutputsSettingsTable'
import CardImage from '../components/views/Cards/Image'
import CardClassificar from '../components/views/Cards/Classificar'
import CardBasic from '../components/views/Cards/Basic'
import CardProfile from '../components/views/Cards/Profile'
import CardFuncionarioProfile from '../components/views/Cards/FuncionarioProfile'
import CardInfo from '../components/views/Cards/Info'
import CardActives from '../components/views/Cards/Actives'
import CardEmployees from '../components/views/Cards/Employees'
import CardEmpresas from '../components/views/Cards/Empresas.vue'
import CardEquipamentos from '../components/views/Cards/Equipamentos.vue'
import Cardestatisticas from '../components/views/Cards/estatisticas.vue'
import CardNovaEmpresa from '../components/views/Cards/nova-empresa.vue'
import Analises from '../components/views/Cards/analises.vue'

import Form from '../components/views/Form'

// Login
import Login from '../views/Login.vue'

// Edit Profile
import Edit from '../views/EditProfile.vue'

// String to Component
const components = {
  Table: Table,
  OutputsSettingsTable: OutputsSettingsTable,
  'Cards.Image': CardImage,
  'Cards.Classificar': CardClassificar,
  'Cards.Basic': CardBasic,
  'Cards.Profile': CardProfile,
  'Cards.FuncionarioProfile': CardFuncionarioProfile,
  'Cards.Info': CardInfo,
  'Cards.Actives': CardActives,
  'Cards.Employees': CardEmployees,
  'Cards.Empresas': CardEmpresas,
  'Cards.Equipamentos': CardEquipamentos,
  'Cards.estatisticas': Cardestatisticas,
  'Cards.nova-empresa': CardNovaEmpresa,
  'Cards.Analises': Analises,
  Form: Form
}

Vue.use(VueRouter)

const dashboardTabs = []

// Generate dynamic routes for dashboard
store.state.dashboard.forEach(module => {
  const state = store.state[module]

  if (state.noView) return

  const path = state.root ? '/' : module

  const mainAction = state.actions

  // Recursive insertion
  function registerRoutes (actions, path, name) {
    actions.filter(a => a.event === 'link' || !a.event).forEach(action => {
      const pathRoute = path + (action.key ? '/' + action.key : '')
      const nameRoute = name + (action.key ? '-' + action.key : '')
      const paramsRoute = (action.params ? '/' + action.params.map(p => ':' + p).join('/') : '')
      const fullPath = pathRoute + paramsRoute
      dashboardTabs.push(...[{
        path: fullPath,
        name: nameRoute,
        component: components[action.component],
        props: action.props
      }])
      if (action.children) {
        registerRoutes(action.children, fullPath /* pathRoute */, nameRoute)
      }
    })
  }
  registerRoutes([mainAction], path, module)
})

const routes = [
  // Dashboard
  {
    path: '/',
    component: Dashboard,
    children: [
      ...dashboardTabs
    ]
  },
  // Login
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLogged) {
        next({ path: '/' })
        return
      }
      next()
    }
  },
  // Edit
  {
    path: '/edit-profile',
    name: 'Edit',
    component: Edit,
    props: true
  },
  // Rota para Empresas
  {
    path: '/empresas',
    name: 'Empresas',
    component: CardEmpresas,
    props: true
  },
  // Rota para Equipamentos
  {
    path: '/equipamentos',
    name: 'Equipamentos',
    component: CardEquipamentos,
    props: true
  },
  // Rota para analises
  {
    path: '/analises',
    name: 'Analises',
    component: Analises,
    props: true
  },
  // Rota para estatísticas
  {
    path: '/estatisticas',
    name: 'estatisticas',
    component: Cardestatisticas,
    props: true
  },
  // Rota para nova-empresa
  {
    path: '/nova-empresa',
    name: 'nova-empresa',
    component: CardNovaEmpresa,
    props: true
  },
  { path: '*', redirect: '/' } // Not Found
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !store.getters.isLogged) {
    next({ name: 'Login' })
    return
  }
  next()
})

export default router
