import { baseMutations, baseActions } from '../functions'
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    // Info
    items: [],
    title: 'Equipamentos',
    name: 'Equipamentos',
    loading: true,

    // Menu
    menu: {
      title: 'Equipamentos',
      icon: { key: 'tools', custom: true }
    },

    // Routes - Components actions
    actions: {
      // Main Action
      component: 'Cards.Equipamentos', // Alterado para 'Cards.Equipamentos'
      props: { title: 'Equipamentos com acesso a plataforma', module: 'users', modelKey: 'profile' },
      children: [
        {
          key: 'add',
          scope: 'Title',
          event: 'link',
          options: { label: 'Novo Equipamento', icon: 'add' },
          component: 'Form',
          props: { title: 'Cadastrar novo equipamento', module: 'users', formKey: 'save', requests: 'add' }
        },
        {
          key: 'edit',
          scope: 'Buttons',
          options: { theme: 'warning' },
          event: 'link',
          params: ['id'],
          component: 'Form',
          props: route => ({ itemId: route.params.id, title: 'Editar equipamento', module: 'users', formKey: 'save', requests: 'edit' })
        },
        {
          key: 'delete',
          scope: 'Buttons',
          options: { theme: 'danger' },
          event: 'modal',
          component: 'Delete',
          props: item => ({ item: item, module: 'users' })
        }
      ]
    },

    // API Get index
    get: {
      urn: 'equipamentos/index', // Rota para buscar equipamentos
      response: 'equipamentos' // Resposta esperada
    },

    // API routes
    requests: {
      add: {
        title: 'Cadastrar novo Equipamento',
        success: 'Equipamento cadastrado com sucesso',
        urn: 'auth/create',
        response: 'equipamento',
        method: 'post',
        dispatch: 'add'
      },
      edit: {
        title: 'Editar Equipamento',
        success: 'Equipamento editado com sucesso',
        urn: 'auth/edit',
        response: 'equipamento',
        method: 'post',
        dispatch: 'edit'
      },
      delete: {
        title: 'Deletar Equipamento',
        success: 'Equipamento deletado com sucesso',
        urn: 'auth/delete?equipamento_id=:id',
        method: 'delete',
        dispatch: 'delete'
      }
    },

    // Models
    models: {
      profile: [
        { key: 'name', label: 'Nome', slot: 'Nome' }
      ]
    },

    // Forms
    forms: {
      save: [
        { key: 'equipamento_id', type: 'hidden', from: 'item', value: 'id' },
        { key: 'name', label: 'Nome', type: 'text', col: 6 },
        { key: 'description', label: 'Descrição', type: 'text', col: 6 }
      ]
    }

  },
  mutations: Object.assign({}, baseMutations, {
    // ...
  }),
  actions: Object.assign({}, baseActions, {
    // ...
    // ...
  })
}
