<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h1>Empresas</h1>
            <b-button variant="primary" @click="abrirModal">
                <i class="fas fa-plus"></i> Nova Empresa
            </b-button>
        </div>

        <div class="box">
            <!-- Filtros de Ordenação -->
            <div class="d-flex justify-content-between">
                <b-form-group>
                    <div class="lineflex">
                        <h2>Filtrar</h2>
                        <b-form-select v-model="ordenacao" :options="ordenacaoOpcoes" @change="ordenarEmpresas"
                            class="boxselect"></b-form-select>
                    </div>
                </b-form-group>
            </div>
            <b-spinner v-if="loading" />
            <div v-if="!loading">
                <b-table :items="items" :fields="fields" striped hover responsive>
                    <template #cell(created_at)="data">
                        <!-- Formatar a data para o formato DD/MM/YYYY -->
                        <span>{{ formatDate(data.item.created_at) }}</span>
                    </template>
                    <template #cell(acoes)="data">
                        <div class="d-flex">
                            <b-button variant="success" class="mr-2"
                                @click="irParaAnalises(data.item.id)">Análises</b-button>
                            <b-button variant="success" class="mr-2"
                                @click="abrirEquipamentosModal(data.item.key)">Equipamentos</b-button>
                        </div>
                    </template>
                </b-table>
            </div>
            <p v-if="error">{{ error }}</p>
        </div>

        <!-- Modal de Nova Empresa -->
        <b-modal id="modal-vazio" ref="modalVazio" hide-footer size="xl" :modal-class="'modal-fullscreen'">
            <div>
                <h1>Nova Empresa</h1>
                <div class="box">
                    <b-form @submit.prevent="onSubmit">
                        <b-row>
                            <b-col md="6">
                                <b-form-group label="Nome">
                                    <b-form-input v-model="form.nome" type="text" required
                                        placeholder="Digite o nome"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Email">
                                    <b-form-input v-model="form.email" type="email" required
                                        placeholder="Digite o email"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="3">
                                <b-form-group label="Senha">
                                    <b-form-input v-model="form.senha" type="password" required
                                        placeholder="Digite a senha"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Confirmar Senha">
                                    <b-form-input v-model="form.confirmarSenha" type="password" required
                                        placeholder="Confirme a senha" :state="senhasConferem"></b-form-input>
                                    <b-form-invalid-feedback :state="senhasConferem">
                                        As senhas não conferem
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Telefone">
                                    <b-form-input v-model="form.telefone" type="text" required
                                        placeholder="(99) 99999-9999" @input="formatarTelefone"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Documento">
                                    <b-form-input v-model="form.documento" type="text" required
                                        placeholder="CPF ou CNPJ" @input="formatarDocumento"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="6">
                                <b-form-group label="CEP">
                                    <b-form-input v-model="form.cep" type="text" required
                                        placeholder="Digite o CEP"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Endereço">
                                    <b-form-input v-model="form.endereco" type="text" required
                                        placeholder="Digite o endereço"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="6">
                                <b-form-group label="Complemento">
                                    <b-form-input v-model="form.complemento" type="text"
                                        placeholder="Digite o complemento"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="UF">
                                    <b-form-input v-model="form.uf" type="text" required
                                        placeholder="Digite o UF"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="6">
                                <b-form-group label="Cidade">
                                    <b-form-input v-model="form.cidade" type="text" required
                                        placeholder="Digite a cidade"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Bairro">
                                    <b-form-input v-model="form.bairro" type="text" required
                                        placeholder="Digite o bairro"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="12">
                                <b-form-group label="Razão Social">
                                    <b-form-input v-model="form.razao_social" type="text" required
                                        placeholder="Digite a razão social"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-button type="submit" variant="primary" :disabled="!formValido">Salvar</b-button>
                    </b-form>
                </div>
            </div>
        </b-modal>

        <!-- Modal de Equipamentos -->
        <equipamentos-modal ref="equipamentosModal" :equipamentos="equipamentos"
            :empresa="empresaSelecionada"></equipamentos-modal>
    </div>
</template>

<style>
.box {
    padding: 10px;
    background-color: #F5F5F5;
    border-radius: 15px;
}

.lineflex {
    display: flex;
    flex-direction: row;
}

.lineflex>h2 {
    margin-right: 10px;
}

.boxselect {
    width: 200px;
    max-width: 200px;
}

.modal-fullscreen {
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
}
</style>

<style lang="stylus" scoped>
@import '../../../style/colors.styl';
@import '../../../style/mixins.styl';

.btn-show
    float right
    margin-top -15px
    border-radius 32px
    background-color content-theme()
    padding 8px 16px
    color #ffffff
    cursor pointer

.block
    display flex
    text-align left
    align-items left
    flex-direction column
    margin 0
    min-width 20%

.img-holder
    width 50px
    height 50px
    border-radius 8px
    background-color #bbb
</style>

<script>
/* eslint-disable */
import { request } from '@/services'
import EquipamentosModal from '../../modals/EquipamentosModal.vue'

export default {
    components: { EquipamentosModal },
    data() {
        return {
            items: [],
            loading: true,
            error: null,
            form: {
                nome: '',
                email: '',
                senha: '',
                confirmarSenha: '',
                telefone: '',
                documento: '',
                cep: '',
                endereco: '',
                complemento: '',
                uf: '',
                cidade: '',
                bairro: '',
                razao_social: ''
            },
            empresas: [],
            equipamentos: [],
            empresaSelecionada: null,
            ordenacao: 'nome',  // Valor inicial para ordenação
            ordenacaoOpcoes: [
                { value: 'nome', text: 'Nome' },
                { value: 'created_at', text: 'Data' }
            ],
            fields: [
                { key: 'key', label: 'Nome' },
                { key: 'created_at', label: 'Data de Criação' },
                { key: 'acoes', label: 'Ações', sortable: false }
            ]
        }
    },
    computed: {
        senhasConferem() {
            return this.form.senha === this.form.confirmarSenha
        },
        formValido() {
            return this.form.nome &&
                this.form.email &&
                this.form.senha &&
                this.senhasConferem &&
                this.form.telefone &&
                this.form.documento &&
                this.form.cep &&
                this.form.endereco &&
                this.form.uf &&
                this.form.cidade &&
                this.form.bairro &&
                this.form.razao_social
        }
    },
    mounted() {
        this.fetchEmpresas();
        this.fetchEquipamentos();
    },
    methods: {
        fetchEmpresas() {
            request.get('empresas/list', response => {
                this.items = response.data.empresas;
                this.empresas = response.data.empresas;
                this.loading = false;
            }, error => {
                console.error('Erro ao buscar empresas:', error);
                this.error = 'Erro ao carregar empresas';
                this.loading = false;
            });
        },
        fetchEquipamentos() {
            request.get('equipamento/list', response => {
                this.equipamentos = response.data.equipamentos;
            }, error => {
                console.error('Erro ao buscar equipamentos:', error);
            });
        },
        abrirModal() {
            this.$refs.modalVazio.show();
        },
        abrirEquipamentosModal(empresaKey) {
            this.empresaSelecionada = empresaKey;
            this.$refs.equipamentosModal.$refs.equipamentosModal.show();
        },
        irParaAnalises(empresaId) {
            this.$router.push({ path: '/analises', query: { empresa_id: empresaId } });
        },
        formatarTelefone(value) {
            let telefone = value.replace(/\D/g, '')
            if (telefone.length > 10) {
                telefone = telefone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
            } else {
                telefone = telefone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
            }
            this.form.telefone = telefone
        },
        formatarDocumento(value) {
            let documento = value.replace(/\D/g, '')
            if (documento.length === 11) {
                documento = documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') // CPF
            } else if (documento.length === 14) {
                documento = documento.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') // CNPJ
            }
            this.form.documento = documento
        },
        formatDate(date) {
            const formattedDate = new Date(date);
            return formattedDate.toLocaleDateString('pt-BR');
        },
        ordenarEmpresas() {
            if (this.ordenacao === 'nome') {
                this.items = this.empresas.sort((a, b) => a.nome.localeCompare(b.nome));
            } else if (this.ordenacao === 'created_at') {
                this.items = this.empresas.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
            }
        },
        onSubmit() {
            if (this.formValido) {
                const payload = {
                    name: this.form.nome,
                    email: this.form.email,
                    password: this.form.senha,
                    password_confirmation: this.form.confirmarSenha,
                    document: this.form.documento,
                    cep: this.form.cep,
                    endereco: this.form.endereco,
                    complemento: this.form.complemento,
                    uf: this.form.uf,
                    cidade: this.form.cidade,
                    bairro: this.form.bairro,
                    razao_social: this.form.razao_social
                };
                request.post('empresas/create', payload, response => {
                    console.log('Empresa criada com sucesso:', response.data);
                    this.$refs.modalVazio.hide();
                    this.fetchEmpresas();
                }, error => {
                    console.error('Erro ao criar empresa:', error);
                    this.error = 'Erro ao criar empresa';
                });
            }
        }
    }
}
</script>