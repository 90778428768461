<template>
  <b-modal id="equipamentos-modal" ref="equipamentosModal" title="Lista de Equipamentos">
    <b-list-group>
      <b-list-group-item v-for="equipamento in equipamentos" :key="equipamento.id"
        class="d-flex justify-content-between align-items-right">
        <b-form-checkbox :checked="selectedEquipamentos.includes(equipamento.nome)"
          @change="atualizarEquipamento(equipamento)" class="mr-2">
          {{ equipamento.nome }}
        </b-form-checkbox>
      </b-list-group-item>
    </b-list-group>
  </b-modal>
</template>

<script>
/* eslint-disable */
import { request } from '@/services'; // Corrigir o caminho se necessário

export default {
  props: {
    equipamentos: {
      type: Array,
      required: true,
    },
    empresa: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedEquipamentos: [], // Array para múltiplas seleções
    };
  },
  watch: {
    empresa: {
      immediate: true,
      handler(novaEmpresa) {
        this.carregarEquipamentos(novaEmpresa); // Carrega equipamentos ao alterar empresa
      },
    },
  },
  methods: {
    carregarEquipamentos(nomeEmpresa) {
      request.post(
        'equipamento/listempresa',
        { empresa: nomeEmpresa },
        (response) => {
          if (response.data.equipamentos) {
            const equipamentosRecebidos = response.data.equipamentos.map(
              (equipamento) => equipamento.nome
            );
            this.selectedEquipamentos = equipamentosRecebidos; // Marca todos os equipamentos recebidos
          } else {
            console.error('Resposta não contém a propriedade equipamentos:', response);
            this.selectedEquipamentos = []; // Limpa a seleção se não houver equipamentos
          }
        },
        (error) => {
          console.error('Erro ao carregar equipamentos:', error);
        }
      );
    },
    atualizarEquipamento(equipamento) {
      const equipamentoIndex = this.selectedEquipamentos.indexOf(equipamento.nome);
      const status = equipamentoIndex === -1; // Define status baseado na presença no array

      // Adiciona ou remove o equipamento do array local
      if (status) {
        this.selectedEquipamentos.push(equipamento.nome);
      } else {
        this.selectedEquipamentos.splice(equipamentoIndex, 1);
      }

      const payload = {
        equipamento: equipamento.id,
        empresa: {
          name: this.empresa,
          status: status,
        },
      };

      console.log('Payload enviado:', payload); // Para depuração

      request.post(
        'equipamento/update',
        payload,
        (response) => {
          this.$bvToast.toast('Equipamento atualizado com sucesso!', {
            title: 'Sucesso',
            variant: 'success',
            solid: true,
          });
        },
        (error) => {
          this.$bvToast.toast('Erro ao atualizar equipamento.', {
            title: 'Erro',
            variant: 'danger',
            solid: true,
          });
          console.error('Erro ao atualizar equipamento:', error);
        }
      );
    },
  },
};
</script>
