import { baseMutations, baseActions } from '../functions'

export default {
  namespaced: true,
  state: {
    // Info
    items: [],
    title: 'Usuários',
    name: 'Usuário',
    loading: true,

    // Menu
    menu: {
      title: 'Usuários',
      icon: { key: 'users', custom: true }
    },

    // Routes - Components actions
    actions: {
      // Main Action
      component: 'Cards.Profile',
      props: { title: 'Usuários com acesso a plataforma', module: 'users', modelKey: 'profile' },
      children: [
        {
          key: 'add',
          // rule: quem tem acesso
          // rule: users.add
          scope: 'Title',
          event: 'link',
          options: { label: 'Novo Usuário', icon: 'add' },
          component: 'Form',
          props: { title: 'Cadastrar novo usuário', module: 'users', formKey: 'save', requests: 'add' }
        },
        {
          key: 'ativesUser',
          scope: 'Buttons',
          options: { theme: 'success', assets: true },
          event: 'redirect',
          params: item => ({ userId: item.id, systemSUI: item.sui })
        },
        {
          key: 'edit',
          scope: 'Buttons',
          options: { theme: 'warning' },
          event: 'link',
          params: ['id'],
          component: 'Form',
          props: route => ({ itemId: route.params.id, title: 'Editar usuário', module: 'users', formKey: 'save', requests: 'edit' })
        },
        {
          key: 'delete',
          scope: 'Buttons',
          options: { theme: 'danger' },
          event: 'modal',
          component: 'Delete',
          props: item => ({ item: item, module: 'users' })
        }
      ]
    },

    // API Get index
    get: {
      urn: 'user/index',
      response: 'users'
    },

    // API routes
    requests: {
      add: {
        title: 'Cadastrar novo Usuário',
        success: 'Usuário cadastrado com successo',
        urn: 'auth/create',
        response: 'user',
        method: 'post',
        dispatch: 'add'
      },
      edit: {
        title: 'Editar Usuário',
        success: 'Usuário editado com successo',
        urn: 'auth/edit',
        response: 'user',
        method: 'post',
        dispatch: 'edit'
      },
      delete: {
        title: 'Deletar Usuário',
        success: 'Usuário deletado com successo',
        urn: 'auth/delete?user_id=:id',
        method: 'delete',
        dispatch: 'delete'
      }
    },

    // Models
    models: {
      profile: [
        { key: 'email', label: 'Email', slot: 'Nome' },
        { key: 'telephone', label: 'Telefone', slot: 'Nome' },
        { key: 'document', label: 'Documento', slot: 'Nome' }
      ]
    },

    // Forms
    forms: {
      save: [
        { key: 'user_id', type: 'hidden', from: 'item', value: 'id' },
        { key: 'name', label: 'Nome', type: 'text', col: 6 },
        { key: 'email', label: 'Email', type: 'text', col: 6 },
        { key: 'password', label: 'Senha', type: 'password', col: 3 },
        { key: 'password_confirmation', label: 'Confirmar Senha', type: 'password', col: 3 },
        {
          key: 'telephone',
          label: 'Telefone',
          type: 'text',
          col: '3',
          mask: ['(##) #####-####'],
          required: true
        },
        {
          key: 'document',
          label: 'Documento',
          type: 'text',
          col: '3',
          mask: ['###.###.###-##'],
          required: true
        },
        { key: 'group_key', label: 'Grupo', type: 'select', from: 'store', value: 'groups.items', options: { value: 'key', text: 'key' }, col: 12 }
      ]
    }

  },
  mutations: Object.assign({}, baseMutations, {
    // ...
  }),
  actions: Object.assign({}, baseActions, {
    // ...
  })
}
